import { use } from '../../redux/factory';
import { ObjectType } from '../../redux/types';
import useFlatObjectsList from './useFlatObjectsList';

const useIsUniqueName = (exclude?: ObjectType | null) => {
  const flatObjectsList = useFlatObjectsList();

  if (!flatObjectsList) return null;

  return (newName: string) => !flatObjectsList.find(
    ({ name, id }) => (
      name.trim().toLowerCase() === newName.trim().toLowerCase()
      && (!exclude || id !== exclude.id)
    )
  );
}

export default useIsUniqueName;
