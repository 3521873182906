import { useMemo } from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import { use } from '../../redux/factory';
import {
  LocalizationKeyType,
  Language,
  LanguagesList,
  LocalizationKey,
  LocalizationKeysList,
  LocalizationString,
  LocalizationStringsList,
} from '../../redux/types';
import {
  SmallSpinner,
  DataTable,
  useLocalization,
  DataTableColumn,
  OnMoveProps,
  useSetState,
} from '../shared/ReactToolbox';
import EditLanguageStringsModal from './EditLanguageStringsModal';

type LanguageListState = {
  localizationKeyInEditModal: null | LocalizationKey;
  typeTab: 'translations' | 'statuses',
}
const InitialState: LanguageListState = {
  localizationKeyInEditModal: null,
  typeTab: 'translations',
}

const LocalizationKeysListComponent = () => {
  const [state, setState] = useSetState(InitialState);
  const { languagesList } = use.languages();
  const { localizationKeysList, moveLocalizationKey } = use.localizationKeys();
  const { localizationStringsList } = use.localizationStrings();
  const { text, lang: selectedIso } = useLocalization();

  const languageId = Object.values(languagesList as LanguagesList).find(
    ({ iso }: Language) => iso === selectedIso
  )?.id || 1;

  const translationsByKey = useMemo(() => {
    if (!localizationKeysList || !localizationStringsList) return;

    return (
      Object.fromEntries(
        Object.values(localizationKeysList as LocalizationKeysList).map(
          ({ id }: LocalizationKey) => [
            id,
            Object.values(localizationStringsList as LocalizationStringsList).find(
              ({key, language}: LocalizationString) => key === id && language === languageId
            )?.text,
          ]
        )
      )
    );
  }, [localizationKeysList, localizationStringsList, languageId]);

  if (!languagesList || !localizationKeysList || !localizationStringsList || !translationsByKey) {
    return <SmallSpinner />;
  }

  const columns = [
    {
      name: text`localization_key`,
      selector: 'value',
      orderBy: 'value',
    },
    {
      name: text`translation`,
      selector: ({ id }: LocalizationKey) => {
        const translation = translationsByKey[id];
        if (typeof translation === 'undefined') {
          return <i>{text`not_found`}</i>;
        }
        return translation;
      },
      orderBy: ({ id }: LocalizationKey) => {
        const translation = translationsByKey[id];
        if (typeof translation === 'undefined') {
          return <i>{text`not_found`}</i>;
        }
        return translation;
      },
    }
  ] as DataTableColumn<LocalizationKey>[];
  
  return (
    <>
      <Tabs
        activeKey={state.typeTab}
        onSelect={(typeTab: string | null) =>
          setState({ typeTab: (typeTab || 'translations') as 'translations' | 'statuses' })
        }
      >
        <Tab eventKey='translations' title={text`manage_translations`} />
        <Tab eventKey='statuses' title={text`manage_statuses`} />
      </Tabs>
      
      <DataTable
        className='localization-keys-datatable'
        rowsPerPage={25}
        filterColumn={({ value, id }: LocalizationKey) => `${ value } ${ translationsByKey[id] || '' }`}
        columns={columns}
        data={
          Object.values(localizationKeysList as LocalizationKeysList)
            .filter(
              ({ type }: LocalizationKey) =>
                state.typeTab === 'statuses'
                  ? type === LocalizationKeyType.STATUS
                  : type !== LocalizationKeyType.STATUS 
          )}
        onMove={({ item, target, reset }: OnMoveProps<LocalizationKey>) => {
          moveLocalizationKey(
            {
              localizationKey: item,
              target,
              position: item.order > target.order ? 'right' : 'left',
            },
            {
                callback: reset,
                onError: reset,
            }
          );
        }}
        orderByDefault='order'
        onClickRow={(localizationKey: LocalizationKey) => setState({ localizationKeyInEditModal: localizationKey })}
      />

      {state.localizationKeyInEditModal &&
        <EditLanguageStringsModal
          localizationKey={state.localizationKeyInEditModal}
          onHide={() => setState({ localizationKeyInEditModal: null })}
        />
      }
    </>
  )
}
export default LocalizationKeysListComponent;