import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';

import { use } from '../../redux/factory';
import {
  useSetState,
  usePrevious,
  DeleteConfirmButton,
  EditButton,
  CopyButton,
  CreateEditModal as CopyModal,
  CreateEditModal as EditModal,
  useLocalization,
} from '../shared/ReactToolbox';
import ClientFormField from '../clients/ClientFormField';
import { Project, Client } from '../../redux/types';

export const formatXml = ({ project, client }: { project: Project; client: Client }) =>
`<?xml version="1.0" encoding="UTF-8"?>
<Interface>
  <Version>0</Version>
  <SalesOrderList>
    <SalesOrder>
      <Verkooporder>${project.sales_order}</Verkooporder>
      <ProjectCode>${project.project_number}</ProjectCode>
      <Klant>${client.client_number}</Klant>
      <Klantnaam>${client.name}</Klantnaam>
      <Werkomschrijving>${project.name}</Werkomschrijving>
      <Prefix>${project.sales_order_prefix}</Prefix>
      <Contactpersoon />
      <TelefoonnummerContactpersoon />
      <EmailContactpersoon />
      <Tekenaar />
      <TelefoonnummerTekenaar />
    </SalesOrder>
  </SalesOrderList>
</Interface>`;

export const useFormFields = () => {
  const { text } = useLocalization();
  return (
    {
      name: { label: text`name`, required: true },
      project_number: { label: text`project_number` },
      sales_order_prefix: { label: text`sales_order_prefix` },
      sales_order: { label: text`sales_order` },
    }
  );
}

export type ProjectItemProps = { project: Project; selected: boolean }
const ProjectItem = ({ project, selected }: ProjectItemProps) => {
  const { text } = useLocalization();
  const formFields = useFormFields();
  const prevSelected = usePrevious(selected);
  const { getAllPhases } = use.phases();
  const { getAllObjects } = use.objects();
  const { selectedClient } = use.clients();
  const { projectsList } = use.projects();
  const { deleteProject, copyProject, selectProject, selectedProjectId, updateProject, getExports } = use.projects();

  useEffect(() => {
    if (selected && selected !== prevSelected) {
      getAllPhases({ params: { project: project.id }});
      getAllObjects({ params: { project: project.id }});
      getExports(project)
    }
  }, [selected, prevSelected]);

  const [state, setState] = useSetState({
    currentStatusId: 1,
    objectsSelected: {},
    showEditModal: false,
    showCopyModal: false,
  });

  const { userFactory: factory } = use.factories()

  return (
    <div
      onClick={(e) => {
        if (project.id !== selectedProjectId) {
          selectProject(project);
        } else {
          getAllPhases({ params: { project: selectedProjectId }});
          getAllObjects({ params: { project: selectedProjectId }});
          getExports(selectedProjectId);
        }
      }}
    >
      {selected
        ? (
          <Link className="w-100 btn btn-primary" to="/">
            <span className="float-start">
              {project.name} {project.project_number && `(${project.project_number})`}
            </span>
            <span className="float-end"
              onClick={e => e.stopPropagation()}>
              {factory.can_edit_clients_and_projects &&
                <>
                  <EditButton
                    title={text`edit_project`}
                    variant="info"
                    onClick={() => setState({ showEditModal: true })}
                  />
                  <CopyButton
                    title={text`copy_project`}
                    variant="info"
                    onClick={() => setState({ showCopyModal: true })}
                  />
                </>
              }
              <DeleteConfirmButton
                title={text`delete_project`}
                variant="info"
                modalTitle={text`delete_project`}
                loading={deleteProject.isLoading}
                onDelete={() => deleteProject(project)}
              />
            </span>
            <CopyModal
              show={state.showCopyModal}
              modalTitle={text`copy_project`}
              onHide={() => setState({ showCopyModal: false })}
              initialState={{
                ...project,
                project_number: null,
                sales_order: null,
                sales_order_prefix: null,
              } as Project}
              formFields={{
                client: { label: text`client`, component: ClientFormField },
                ...formFields,
                name: { label: text`new_name`, required: true },
              }}
              loading={copyProject.isLoading}
              onSave={(newProject: any) => {
                copyProject(
                project,
                {
                  args: {
                    newProject: {
                      ...newProject,
                      xml: formatXml({ project: newProject, client: selectedClient }),
                    },
                  },
                  callback: (newProject: Project) => {
                    selectProject(newProject);
                    setState({ showCopyModal: false })
                    toast.success(text`project_copied_successfully`);
                  },
                })}}
            />
            <EditModal
              show={state.showEditModal}
              modalTitle={text`edit_project`}
              onHide={() => setState({ showEditModal: false })}
              initialState={project as Project}
              formFields={formFields}
              loading={updateProject.isLoading}

              validate={({ name }: any) =>
                Object
                  .values(projectsList)
                  .find((p: any) => name === p.name && p.id !== project.id)
                    ? { name: text`project_name_not_unique_error` }
                    : false
              }
              onSave={(project: any) => {
                updateProject({
                  ...project,
                  xml: formatXml({ project, client: selectedClient }),
                },
                { callback: () => setState({ showEditModal: false }) }
                );
              }}
            />

            <br />
            <span className="float-start">
              <small>
                {project.sales_order_prefix && <>{project.sales_order_prefix}&nbsp;</>} {project.sales_order}
              </small>
            </span>
          </Link>
        ) : (
          <Link className="w-100 btn btn-link" to="/">
            <span className="float-start">
              {project.name} {project.project_number && `(${project.project_number})`}
            </span>
          </Link>
        )
      }
    </div>
  );
};
ProjectItem.propTypes = {
  project: PropTypes.object.isRequired,
  selected: PropTypes.bool.isRequired,
}

export default ProjectItem;
