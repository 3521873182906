import React, { useEffect } from 'react';
import _ from 'lodash';
import { toast } from 'react-toastify';
import { Navbar, Nav, Row, Col } from 'react-bootstrap';
import { Link, Outlet, useNavigate } from 'react-router-dom';

import { useAuth, useLogin } from '../shared/Login';
import { use } from '../../redux/factory';
import {
  SmallSpinner,
  useSetState,
  ErrorPage,
  usePrevious,
  useInterval,
  useLocalization,
} from '../shared/ReactToolbox';
import { Image } from '../shared/Images';
import FactoriesDropdown from '../factories/FactoriesDropdown';
import ProjectsList from '../projects/ProjectsList';
import ClientsDropdown from '../clients/ClientsDropdown';
import UserLanguageDropdown from '../localization/UserLanguageDropdown';

const NavLink = ({ to, children }: { to: string; children: any }) => (
  <Nav.Item>
    <Link to={to} className='nav-item'>
      <div className='nav-link'>{children}</div>
    </Link>
  </Nav.Item>
);

const Dashboard = () => {
  const { logout, getCurrentUser } = useLogin();
  const navigate = useNavigate();
  const [state, setState] = useSetState({
    showCreateObjectModal: false,
    projectTab: 'phases',
  })
  const { clearConfigurationModelsList, getConfigurationModelsList } = use.configurationModels()
  const { getUserFactory, getActiveObjectSelections, changeUserFactory, activeObjectSelections } = use.factories();
  const { selectedClient } = use.clients()
  const { selectedProject } = use.projects()
  const { text, setLanguage } = useLocalization();
  const { user } = useAuth();
  const { getLanguagesList } = use.languages();
  const { getLocalizationStringsList } = use.localizationStrings();
  const { getLocalizationKeysList } = use.localizationKeys();
  const { userFactory: factory } = use.factories();

  useEffect(() => {
    if (user?.language) {
      setLanguage(user.language);
    }
  }, [user?.language]);
  useEffect(() => {
    getCurrentUser({
      callback: () => {
        clearConfigurationModelsList();
        getConfigurationModelsList();
        getUserFactory();
        getActiveObjectSelections();
      },
    });
  }, []);
  useEffect(() => {
    if (user?.is_staff) {
      getLanguagesList();
      getLocalizationStringsList();
      getLocalizationKeysList();
    }
  }, [user?.is_staff]);

  const prevSelectedProject = usePrevious(selectedProject);

  // Trigger "Wachtrij" every minute
  useInterval(getActiveObjectSelections, 60000);

  useEffect(() => {
    if (!_.isEqual(prevSelectedProject, selectedProject)) {
      setState({ projectTab: 'phases' });
    }
  }, [selectedProject, prevSelectedProject]);

  if (user && !user.factory && !user.is_staff) {
    return (
      <ErrorPage>
        <p>{text`factory_not_coupled_to_account`}</p>
      </ErrorPage>
    );
  }
  if (!user || getUserFactory.isLoading) return <SmallSpinner />
  return (
    <Row className="dashboard h-100">
      <Col sm="3" className="projects-list h-100">
        <NavLink to="/">
          <Image
            src="/static/images/CADblanche_logo-liggend.png"
            alt="CADblanche"
            className="cadblanche-logo"
          />
        </NavLink>
        {user.is_staff && (
          <div className="admin-nav-links">
            <NavLink to="/factories">
              {text`manage_factories`}
            </NavLink>
            <NavLink to="/localization">
              {text`manage_languages`}
            </NavLink>
            <NavLink to="/emails">
              {text`manage_emails`}
            </NavLink>
          </div>
        )}
        {selectedClient && <ProjectsList />}
      </Col>
      <Col sm="9">
        <Navbar>
          {user.is_staff
            ? <FactoriesDropdown />
            : <Navbar.Brand>
                {factory && (
                  factory.logo
                    ? <Image
                      src={factory.logo}
                      isFrontend={true}
                      alt={factory.name}
                      className="factory-logo"
                    />
                    : factory.name
                )}
              </Navbar.Brand>
          }
          {user.factory && !changeUserFactory.isLoading && <ClientsDropdown />}

          <Navbar.Collapse className="justify-content-end">
            <Navbar.Text style={{ marginRight: '20px'}}>
              {activeObjectSelections !== null
                ? `${text`queue`}: ${activeObjectSelections}`
                : <SmallSpinner component='span'/>
              }
            </Navbar.Text>
            <Navbar.Text>
              <UserLanguageDropdown />
            </Navbar.Text>
            <Navbar.Text>
              <b>{user.name || user.username}</b>
            </Navbar.Text>
            <Nav.Link
              className='nav-link-logout'
              onClick={() => {
                logout();
                toast.success(text`logout_successful`);
                localStorage.clear();
                window.location.href = '/login';
              }}
            >
              {text`logout`}
            </Nav.Link>
          </Navbar.Collapse>
        </Navbar>


        <Outlet />
      </Col>
    </Row>
  );
};

export default Dashboard;
