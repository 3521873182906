import { use } from '../../redux/factory';
import { AllObjectsList } from '../../redux/types';

const useFlatObjectsList = () => {
  // Get all objects lists by phase id
  const { objectsList } = use.objects();
  if (!objectsList) return null;

  return (
    Object
      .values(objectsList as AllObjectsList)
      .flatMap(
        phaseObjects => phaseObjects ? Object.values(phaseObjects) : []
      )
  );
}

export default useFlatObjectsList;
