import { use } from '../../redux/factory';
import { toast } from 'react-toastify';
import {
  SmallSpinner,
  DataTable,
  useLocalization,
  DataTableColumn,
  OnMoveProps,
  CreateButton,
  CopyButton,
  PasteButton,
  DeleteConfirmButton,
  useSetState,
  CreateEditModal as CreateModal,
  CreateEditModal as EditModal,
  FormFields,
  AdditionalLocalization,
} from '../shared/ReactToolbox';

import {
  Language,
  LanguagesList,
} from '../../redux/types';

type LanguageListState = {
  createModalActive: boolean;
  pasteModalActive: boolean;
  languageInEditModal: null | Language;
}
const InitialState: LanguageListState = {
  createModalActive: false,
  pasteModalActive: false,
  languageInEditModal: null,
}
const LanguagesListComponent = () => {
  const [state, setState] = useSetState(InitialState);
  const {
    getLanguagesList,
    languagesList,
    createLanguage,
    updateLanguage,
    deleteLanguage,
    moveLanguage,
    updateAllLocalizationStrings,
  } = use.languages();
  const { getLocalizationStringsList } = use.localizationStrings();
  const { text, localizationStrings, setLocalization } = useLocalization();

  const languageFormFields = {
    iso: { label: text`language_iso`, required: true },
    name: { label: text`language_name`, required: true },
  } as FormFields;

  const columns = [
    {
      name: text`language_iso`,
      selector: 'iso',
    },
    {
      name: text`language_name`,
      selector: 'name',
    },
    {
      name: text`actions`,
      selector: (language: Language) => (
        <>
          <DeleteConfirmButton
            className='float-end'
            onDelete={() => deleteLanguage(language, { callback })}
            title={text`edit_language`}
            loading={deleteLanguage.isLoading && deleteLanguage?.data?.id === language.id}
          />
        </>
      )
    },
  ] as DataTableColumn<Language>[];

  if (!languagesList) return <SmallSpinner />

  const callback = () => {
    getLocalizationStringsList({ callback: () => {
      setState(InitialState)
    } });
  }

  return (
    <div>
      <DataTable
        showHeader={false}
        rowsPerPage={null}
        columns={columns}
        data={Object.values(languagesList as LanguagesList)}
        onMove={({ item, target, reset }: OnMoveProps<Language>) => {
          moveLanguage(
            {
              language: item,
              target,
              position: item.order > target.order ? 'right' : 'left',
            },
            {
                callback: reset,
                onError: reset,
            }
          )
        }}
        orderByDefault='order'
        onClickRow={(language: Language) => setState({ languageInEditModal: language})}
      />

      <CreateButton
        className='float-end'
        onClick={() => setState({ createModalActive: true })}
        title={text`new_language`}
      />

      <PasteButton
        className='float-end'
        onClick={() => setState({ pasteModalActive: true })}
        title={text`paste_localization`}
      />

      <CopyButton
        className='float-end'
        onClick={async () => {
          if (!navigator.clipboard) {
            // Clipboard API not available
            console.error('Clipboard API not available');
            return;
          }
          try {
            await navigator.clipboard.writeText(
              JSON.stringify(
                {
                  languages: languagesList && Object.fromEntries(
                    Object.values(languagesList as LanguagesList).map(
                      ({ iso, name }) => [iso, name],
                    )
                  ),
                  localization: localizationStrings,
                },
                null,
                2,
              )
            );
            toast.success(text`localization_copied_to_clipboard`);
          } catch (err) {
            console.error('Failed to copy: ', err);
          }
        }}
        title={text`copy_localization`}
      />

      <CreateModal
        show={state.createModalActive}
        modalTitle={<>{text`new_language`}</>}
        onHide={() => setState({ createModalActive: false })}
        initialState={{ iso: '', name: '' }}
        formFields={languageFormFields}
        loading={createLanguage.isLoading || getLocalizationStringsList.isLoading}
        onSave={(language: any) => {
          createLanguage(
            language,
            {
              callback,
            }
          )
        }}
      />
      {state.languageInEditModal && (
        <EditModal
          show={!!state.languageInEditModal}
          modalTitle={<>{text`edit_language`}</>}
          onHide={() => setState({ languageInEditModal: null })}
          initialState={state.languageInEditModal}
          formFields={languageFormFields}
          loading={updateLanguage.isLoading || getLocalizationStringsList.isLoading}
          onSave={(language: any) => {
            updateLanguage(
              language,
              {
                callback,
              }
            )
          }}
        />
      )}
      {state.pasteModalActive && (
        <EditModal
          dialogClassName="mw-100 w-75"
          modalTitle={<>{text`paste_localization`}</>}
          onHide={() => setState({ pasteModalActive: false })}
          formFields={{
            localization_strings: {
              label: 'json',
              formProps: { as: 'textarea', rows: 15 },
              required: true,
            },
          }}
          loading={updateAllLocalizationStrings?.isLoading}
          onSave={({ localization_strings }: any) => {
            updateAllLocalizationStrings(
              JSON.parse(localization_strings),
              {
                callback: (localization: AdditionalLocalization) => {
                  setLocalization(localization);
                  callback();
                  getLanguagesList();
                  getLocalizationStringsList();
                },
              }
            )
          }}
        />
      )}
    </div>
  )
}
export default LanguagesListComponent;
