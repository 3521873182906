
import React, { ChangeEvent, KeyboardEvent, useEffect } from 'react';
import { Form, Card, Button } from 'react-bootstrap';
import { toast } from 'react-toastify';

import { use } from '../../redux/factory';
import { useSetState, useLocalization, SmallSpinner } from '../shared/ReactToolbox';
import {
  Email,
  EmailsList,
} from '../../redux/types';

const EditEmail = ({ email }: { email: Email }) => {
  const { text, lang } = useLocalization();
  const { updateEmail, sendTestEmail } = use.emails({ language: lang });

  const [{ subject, message }, setState] = useSetState({
    subject: email.subject,
    message: email.message,
  });
  useEffect(() => {
    setState({
      subject: email.subject,
      message: email.message,
    });
  }, [lang])
  const handleSave = () => updateEmail({
    ...email,
    subject,
    message,
  });
  const saved = subject === email.subject && message === email.message;

  return (
    <Form>
      <Card className="text-center">
        <Card.Header>
          {email.key}
        </Card.Header>
        <Card.Body>
          <Form.Group className="mb-3">
            <Form.Label>{text`email_subject`}</Form.Label>
            <Form.Control
              type="text"
              value={subject}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                setState({ subject: e.target.value })
              }}
              onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => {
                if (e.key === 'Enter') {
                  // Pressing the enter key will save data unless it is a multi line text area
                  e.preventDefault();
                  handleSave();
                }
              }}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>{text`email_message`}</Form.Label>
            <Form.Control
              as="textarea"
              rows={Math.max(
                (message.match(/\n/g) || []).length + 1,
                5
              )}
              value={message}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                setState({ message: e.target.value })
              }}
            />
          </Form.Group>

          {(sendTestEmail.isLoading || updateEmail.isLoading) && <SmallSpinner />}
          <Button
            variant="primary"
            disabled={!saved}
            onClick={() => sendTestEmail(
              email,
              {
                callback: () => toast.success(text`send_test_email_successful`)
              }
            )}
            className='icon-button'
          >
            {text`send_test_email`}
          </Button>

          <Button
            variant="primary"
            disabled={saved}
            onClick={() => handleSave()}
            className='icon-button'
          >
            {text`save`}
          </Button>
        </Card.Body>
      </Card>
    </Form>
  );
};
const ManageEmailsPage = () => {
  const { lang } = useLocalization();
  const { getAllEmails, emailsList } = use.emails({ language: lang});
  useEffect(() => {
    getAllEmails();
  }, []);

  if (!emailsList) return <SmallSpinner />;
  return (
    <>{
      Object.values(emailsList as EmailsList).map(
        (email: Email) => <EditEmail email={email} key={email.key} />
      )
    }</>
  );
}

export default ManageEmailsPage;
