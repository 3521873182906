
import React, { useState, useRef, MouseEvent, useEffect } from 'react';
import { Modal, Form, InputGroup } from 'react-bootstrap';

import { use } from '../../redux/factory';
import { useLocalization, SaveButton, AdditionalLocalization } from '../shared/ReactToolbox';
import {
  LocalizationKeyType,
  Language,
  LanguagesList,
  LocalizationKey,
  LocalizationKeysList,
  LocalizationString,
  LocalizationStringsList,
} from '../../redux/types';

type EditLocalizationFormControlProps = {
  language: Language;
  localizationString: LocalizationString | undefined;
};
const EditLocalizationFormControl: React.FC<EditLocalizationFormControlProps> = ({ language, localizationString}) => {
  const { text, setLocalization } = useLocalization();
  const { getLocalizationStrings, localizationStrings } = use.languages();
  const { updateLocalizationString } = use.localizationStrings();
  const [textModified, setTextModified] = useState(localizationString?.text || '');
  const { lang } = useLocalization();
  const inputRef = useRef<HTMLInputElement>(null);

  const onSave = () => updateLocalizationString(
    {
      ...localizationString,
      text: textModified,
    },
    { callback: () => {
      getLocalizationStrings();
      if (inputRef.current) {
        inputRef.current.blur();
      }
    }}
  );
  useEffect(() => {
    setLocalization(localizationStrings);
  }, [localizationStrings]);
  

  return (
    <Form.Group className="mb-3">
      <Form.Label>{language.name}</Form.Label>
      <InputGroup>
        {localizationString
          ? (
            <Form.Control
              type="text"
              value={textModified}
              onChange={(e) => setTextModified(e.target.value)}
              onKeyPress={e => {
                if (e.charCode === 13) {
                  e.preventDefault();
                  onSave();
                }
              }}
              ref={inputRef}
            />
          ) : (
            <i>{text`not_found`}</i>
          )
        }
        <SaveButton
          disabled={localizationString?.text === textModified}
          variant='outline-secondary'
          onClick={() => onSave()}
          loading={
            updateLocalizationString.isLoading
            && updateLocalizationString.data.language === language.id}
        />
      </InputGroup>
    </Form.Group>
  )
}

type EditLanguageStringsModalProps = {
  onHide: () => void;
  localizationKey: LocalizationKey;
};


const EditLanguageStringsModal: React.FC<EditLanguageStringsModalProps> = ({ onHide, localizationKey }) => {
  const { text } = useLocalization();
  const { languagesList } = use.languages();
  const { localizationStringsList } = use.localizationStrings();

  //useMemo(languagesList && localizationStringsList && (
  const list = 
    Object.values(languagesList as LanguagesList).map((language: Language) => ({
      language,
      localizationString: Object.values(localizationStringsList as LocalizationStringsList).find(
        (lS: LocalizationString) => lS.language === language.id && lS.key === localizationKey.id
      )
    })) as EditLocalizationFormControlProps[]
  // ), [languagesList, localizationStringsList]);

  return (
    
    <Modal
      show={true}
      onHide={onHide}
      onClick={(e: MouseEvent) => e.stopPropagation()}
      dialogClassName="mw-100 w-75"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>{text`edit_texts`}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Form>
          <Form.Group className="mb-3">
            <Form.Label>{text`localization_key`}</Form.Label>
            <InputGroup>
              <Form.Control value={localizationKey.value} disabled/>
            </InputGroup>
          </Form.Group>
              
          {list && list.map(({ language, localizationString }: EditLocalizationFormControlProps, key: number) => (
              <EditLocalizationFormControl {...{ key, language, localizationString }} />
            ))
          }
        </Form>
      </Modal.Body>
    </Modal>
  );
}

export default EditLanguageStringsModal;