import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { use } from '../../redux/factory';
import PhasesList from './PhasesList';
import MovePhaseModalButton from './MovePhaseModalButton';
import ObjectsList from '../objects/ObjectsList';
import CreateObjectModal from '../objects/CreateObjectModal';
import {
  DeleteConfirmButton,
  CreateFileButton,
  EditButton,
  UpButton,
  DownButton,
  CreateEditModal,
  useSetState,
  useLocalization,
} from '../shared/ReactToolbox';

const Phase = ({
  phase,
  onMoveUp,
  onMoveDown,
}) => {
  const { text } = useLocalization();
  const { updatePhase, deletePhase, movePhase } = use.phases();
  const { objectsList, getAllObjects } = use.objects();

  const [state, setState] = useSetState({
    showEditModal: false,
    showCreateObjectModal: false,
    showObjects: true,
  })

  if (getAllObjects.isLoading) return null;

  return (
    <div
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        setState({ showObjects: !state.showObjects });
      }}
    >
      <span>
        {state.showObjects
          ? <strong>{phase.name}</strong>
          : phase.name
        }
        <div className="float-end">
          {(!objectsList || Object.values(objectsList).length === 0) && <>
            <CreateFileButton
              title={text`configure_new_object`}
              onClick={() => setState({ showCreateObjectModal: true })}
            />
          </>}
          {onMoveUp && <UpButton onClick={onMoveUp} />}
          {onMoveDown && <DownButton onClick={onMoveDown} />}
          <MovePhaseModalButton phase={phase} loading={movePhase.isLoading}/>
          <EditButton
            title={text`edit_phase`}
            onClick={() => setState({ showEditModal: true })}
          />
          <DeleteConfirmButton
            title={text`delete_phase`}
            onDelete={() => deletePhase(phase)}
          />
        </div>
      </span>
      <br />
      <br />
      {state.showEditModal &&
        <CreateEditModal
          modalTitle={text`edit_phase`}
          loading={updatePhase.isLoading}
          onHide={() => setState({ showEditModal: false })}
          initialState={phase}
          formFields={{ name: { label: text`name` } }}
          onSave={updatePhase}
        />
      }
      <div>
        {state.showObjects && objectsList && Object.values(objectsList).length > 0 && (
          <ObjectsList phase={phase} />
        )}
        <PhasesList parent={phase} />

      </div>
      <CreateObjectModal
        phase={phase}
        show={state.showCreateObjectModal}
        onHide={() => setState({ showCreateObjectModal: false })}
      />
    </div>
  );
};
Phase.propTypes = {
  phase: PropTypes.object.isRequired,
  onMoveUp: PropTypes.func,
  onMoveDown: PropTypes.func,
}
Phase.defaultProps = {
  onMoveUp: null,
  onMoveDown: null,
}

export default Phase;
