import React, { useState } from 'react';
import { Nav, NavDropdown } from 'react-bootstrap';
import { AiFillEdit } from 'react-icons/ai';

import { useAuth } from '../shared/Login';
import { use } from '../../redux/factory';
import {
  useLocalization,
  defaultLanguages,
} from '../shared/ReactToolbox';
import EditLocalizationModal from './EditLocalizationModal';
import { UserFactory, LanguagesList, Language } from '../../redux/types';

const UserLanguageDropdown = () => {
  const { userFactory } = use.factories();
  const [showEditLocalizationModal, setShowEditLocalizationModal] = useState(false);
  const { lang, setLanguage } = useLocalization();
  const { setUserLanguage, languagesList } = use.languages();
  const { user } = useAuth();
  const { getConfigurationModelsList } = use.configurationModels();

  if (!userFactory?.languages) return null;

  const userLanguages = (
    user.is_staff 
      ? (
        languagesList ? Object.fromEntries(
          Object.values(languagesList as LanguagesList).map((l: Language) => [l.iso, l.name])
        ) : {}
      ) : (
        userFactory.languages as UserFactory['languages']
      )
  );

  return (
    <Nav className="mr-auto">
      <NavDropdown title={lang.toUpperCase()}>
        {Object.entries(userLanguages).map(([iso, name]: [string, string], index) =>
          <NavDropdown.Item
            key={index}
            onClick={() => setUserLanguage(
              iso,
              { callback: ({ language }: { language: string }) => {
                setLanguage(language);
                // Reload configuration models with the description in the newly selected language
                getConfigurationModelsList();
              }}  
            )}
          >
            {lang === iso
              ? <strong>{name}</strong>
              : name
            }
          </NavDropdown.Item>
        )}
        {user.is_staff &&
          <>
            <NavDropdown.Divider />
            <NavDropdown.Item
                onClick={() => setShowEditLocalizationModal(true)}
            >
                <AiFillEdit />
            </NavDropdown.Item>
            {showEditLocalizationModal &&
              <EditLocalizationModal onHide={() => setShowEditLocalizationModal(false)} />
            }
          </>
        }
      </NavDropdown>
    </Nav>
  )
};

export default UserLanguageDropdown;