import { use } from '../../redux/factory';
import { FormDropdown } from '../shared/ReactToolbox';

const ClientFormField = props => {
  const { clientsList } = use.clients();
  return (
    <FormDropdown
      list={Object.values(clientsList)}
      {...props}
    />
  );
}

export default ClientFormField;

