import React, { useEffect, useMemo } from 'react';
import Fuse from 'fuse.js';
import { Nav, NavDropdown } from 'react-bootstrap';
import Client, { formatClient } from './Client';
import { useAuth } from '../shared/Login';
import {
  CreateButton,
  EditButton,
  DeleteConfirmButton,
  SmallSpinner,
  SearchBox,
  useLocalization,
  CreateEditModal as CreateModal,
  CreateEditModal as EditModal,
} from '../shared/ReactToolbox';
import { useSetState } from '../shared/ReactToolbox';
import UploadXmlButton from '../projects/UploadXmlButton';
import { use } from '../../redux/factory';

const ClientsDropdown = () => {
  const [state, setState] = useSetState({
    createModalActive: false,
    editModalActive: false,
    search: '',
  });
  const {
    getClientsList,
    selectedClient,
    clientsList,
    selectClient,
    selectedClientId,
    createClient,
    updateClient,
    deleteClient,
  } = use.clients();
  useEffect(() => { getClientsList(); }, []);
  const { userFactory: factory } = use.factories();
  const { getFactoriesList, factoriesList } = use.factories()
  const { text } = useLocalization();
  const { user } = useAuth();

  const fuse = useMemo(() => new Fuse(
    clientsList ? Object.values(clientsList) : [],
    {
      findAllMatches: true,
      threshold: 0.2,
      shouldSort: false,
      includeScore: true,
      keys: ['name', 'client_number'],
    }
  ), [clientsList]);

  if (getFactoriesList.isLoading || (user?.is_staff && !factoriesList)) return null;
  if (getClientsList.isLoading || !factory) return <SmallSpinner />;
  const result = fuse.search(state.search)

  const clientFormFields = {
    name: { label: text`name`, required: true },
    client_number: { label: text`client_number` },
  };

  return (
    <Nav className='mr-auto'>
      {clientsList &&
        <NavDropdown
          title={selectedClient ? formatClient(selectedClient) : text`select_a_client`}
          id='basic-nav-dropdown'
        >
          <SearchBox
            className="dropdown-control"
            value={state.search}
            onChange={search => setState({ search })}
            onClear={() => setState({ search: '' })}
          />

          {(state.search
              ? fuse.search(state.search).map(({ item }) => item)
              : Object.values(clientsList)
            ).map(client => (
            <Client
              key={client.id}
              client={client}
              selected={client.id === selectedClientId}
            />
          ))}
        </NavDropdown>
      }

      {factory.can_edit_clients_and_projects && <>
        <CreateButton
          title={text`create_new_client`}
          onClick={() => setState({ createModalActive: true })}
        />
        <CreateModal
          show={state.createModalActive}
          modalTitle={text`new_client`}
          onHide={() => setState({ createModalActive: false })}
          initialState={{ name: '', client_number: null }}
          formFields={clientFormFields}
          loading={createClient.isLoading}
          onSave={newClient => createClient(
            newClient,
            {
              callback: newClient => {
                selectClient(newClient);
                setState({ createModalActive: false })
              },
            }
          )}
        />
      </>}
      <UploadXmlButton />

      {selectedClient && factory.can_edit_clients_and_projects && <>
        <EditButton
          title={text`edit_client`}
          onClick={() => setState({ editModalActive: true })}
        />
        <EditModal
          show={state.editModalActive}
          modalTitle={text`edit_client`}
          onHide={() => setState({ editModalActive: false })}
          initialState={selectedClient}
          formFields={clientFormFields}
          loading={updateClient.isLoading}
          onSave={client => updateClient(
            client,
            { callback: () => setState({ editModalActive: false }) }
          )}
        />
      </>}
      <DeleteConfirmButton
        title={text`delete_client`}
        modalTitle={text`delete_client`}
        disabled={!selectedClientId}
        loading={deleteClient.isLoading}
        onDelete={() => deleteClient(selectedClient)}
      />
    </Nav>
  );
};

export default ClientsDropdown;
