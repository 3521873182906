import React, { useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { use } from '../../redux/factory';
import { useLocalization } from '../shared/ReactToolbox';

const UserLanguageDropdown = () => {
  const { lang, setLanguage } = useLocalization();
  const { localizationStrings } = use.languages();

  return (
    <Dropdown className='language-dropdown'>
      <Dropdown.Toggle variant="outline-primary">
        {lang.toUpperCase()}
      </Dropdown.Toggle>

      <Dropdown.Menu>
        {Object.keys(localizationStrings)
          .map((iso: string) => 
            <Dropdown.Item 
              onClick={() => setLanguage(iso)}
              key={iso}
            >
              {iso}
            </Dropdown.Item>
        )}
      </Dropdown.Menu>
    </Dropdown>
  )
};

export default UserLanguageDropdown;