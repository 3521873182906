import React, { MouseEvent } from 'react';
import { ConfigurationModel } from '@elfsquad/configurator';
import PropTypes from 'prop-types';
import { Form, Modal, Button } from 'react-bootstrap';
import { LoadingIndicator, SmallSpinner, useSetState, useLocalization } from '../shared/ReactToolbox';
import { use } from '../../redux/factory';
import { stripHtml } from '../../utils/utils';
import useIsUniqueName from './useIsUniqueName';

export type CreateObjectModalProps = {
  show: boolean;
  onHide: () => void;
  phase: number | null;
};

type InitialState = {
  configurationModelUuidSelected: string | null;
  name: string;
}
const CreateObjectModal = ({ show, onHide, phase }: CreateObjectModalProps) => {
  const { selectedProject, selectedProjectId } = use.projects();
  const { configureObject} = use.objects({ phase });
  const { text } = useLocalization();
  const isUniqueName = useIsUniqueName();
  const { userFactory: factory } = use.factories();

  const initialState = {
    configurationModelUuidSelected: null,
    name: '',
  } as InitialState
  const [state, setState] = useSetState(initialState);

  const onCreateObject = () => {
    configureObject(
      {
        configurationModelUuid: state.configurationModelUuidSelected,
        project: selectedProject,
        phase,
        name: state.name,
      },
      { callback: hide },
    );
  }

  const hide = () => {
    setState(initialState);
    onHide();
  }

  const { configurationModelsList } = use.configurationModels();

  if (!configurationModelsList || !selectedProjectId || !isUniqueName || !factory) {
    return null;
  }
  if (!configurationModelsList) return <LoadingIndicator />;
  const { configurationModelUuidSelected } = state;

  const isInvalid = !isUniqueName(state.name);
  const disabled = (
    configurationModelUuidSelected === null
    || configureObject.isLoading
    || isInvalid
  );

  return <>
    <Modal
      show={show}
      onHide={hide}
      onClick={(e: MouseEvent) => e.stopPropagation()}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>{text`configure_object`}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Form>
          <Form.Group controlId="configurationModel">
            <Form.Label>{text`choose_configuration_model`}</Form.Label>
            <Form.Control
              as="select"
              htmlSize={5}
              onChange={e => setState({ configurationModelUuidSelected: e.target.value })}
            >
              {(Object.values(configurationModelsList) as ConfigurationModel[])
                // .sort(({ order: a }, { order: b }) => (a - b))
                .map(({ description, featureModelId }) => (
                  <option
                    key={featureModelId}
                    value={featureModelId}
                    defaultValue={configurationModelUuidSelected || ''}
                    onClick={() => setState({ configurationModelUuidSelected: featureModelId })}
                    onDoubleClick={() => { if (!disabled) onCreateObject(); }}
                  >
                    {stripHtml(description)}
                  </option>
                ))}
            </Form.Control>
          </Form.Group>
          <Form.Group controlId="name">
            <Form.Label>
              {text`name`}
              {isInvalid && ` (${text`object_name_not_unique_error`})`}
            </Form.Label>
            <Form.Control
              as="input"
              autoComplete="off"
              value={state.name || ''}
              maxLength={32}
              onChange={e => setState({ name: e.target.value })}
              onKeyDown={e => {
                if (e.key === 'Enter') {
                  // Pressing the enter key will save data
                  e.preventDefault();
                  if (!disabled) onCreateObject();
                }
              }}
              isInvalid={isInvalid}
            />
          </Form.Group>
        </Form>
      </Modal.Body>

      <Modal.Footer>
        {configureObject.isLoading && <SmallSpinner />}
        <Button
          variant="secondary"
          onClick={hide}
        >
          {text`close`}
        </Button>
        <Button
          variant="primary"
          onClick={() => onCreateObject()}
          disabled={disabled}
        >
          {text`save`}
        </Button>
      </Modal.Footer>
    </Modal>
  </>;
}
CreateObjectModal.propTypes = {
  onHide: PropTypes.func.isRequired,
  show: PropTypes.bool,
};
CreateObjectModal.defaultProps = {
  show: true,
};

export default CreateObjectModal;
