import { Container } from 'react-bootstrap';
import { use } from '../../redux/factory';
import {
  SmallSpinner,
  useLocalization,
} from '../shared/ReactToolbox';
import LanguagesList from './LanguagesList';
import LocalizationKeysList from './LocalizationKeysList';

const ManageLocalizationPage = () =>  {
  const { languagesList } = use.languages();
  const { localizationStringsList } = use.localizationStrings();
  const { localizationKeysList } = use.localizationKeys();
  
  if (
    !languagesList
    || !localizationStringsList
    || !localizationKeysList
  ) return <SmallSpinner />
  return (
    <Container className="container-list">
      <LanguagesList />
      <br />
      <LocalizationKeysList />
    </Container>
  )
}

export default ManageLocalizationPage;