
import React, { useState, useRef, MouseEvent } from 'react';
import { Modal, Form, InputGroup } from 'react-bootstrap';

import { use } from '../../redux/factory';
import { useLocalization, SaveButton, AdditionalLocalization } from '../shared/ReactToolbox';

type EditLocalizationFormControlProps = {
  key: any;
  languageString: string;
  text: string;
};
const EditLocalizationFormControl: React.FC<EditLocalizationFormControlProps> = ({ languageString, text}) => {
  const { updateLocalizationString } = use.languages();
  const [textModified, setTextModified] = useState(text);
  const { lang, setLocalization } = useLocalization();
  const inputRef = useRef<HTMLInputElement>(null);

  const onSave = () => updateLocalizationString(
    {
      language: lang,
      key: languageString,
      text: textModified,
    },
    { callback: (localization: AdditionalLocalization) => {
      setLocalization(localization);
      if (inputRef.current) {
        inputRef.current.blur();
      }
    }}
  )

  return (
    <Form.Group className="mb-3">
      <Form.Label>{languageString}</Form.Label>
      <InputGroup>
        <Form.Control
          type="text"
          value={textModified}
          onChange={(e) => setTextModified(e.target.value)}
          onKeyPress={e => {
            if (e.charCode === 13) {
              e.preventDefault();
              onSave();
            }
          }}
          ref={inputRef}
        />
        <SaveButton
          disabled={text === textModified}
          variant='outline-secondary'
          onClick={() => onSave()}
          loading={updateLocalizationString.isLoading && updateLocalizationString.data.key === languageString}
        />
      </InputGroup>
    </Form.Group>
  )
}

type EditLocalizationModalProps = {
  onHide: () => void;
};

const EditLocalizationModal: React.FC<EditLocalizationModalProps> = ({ onHide }) => {
  const { text, strings } = useLocalization();

  return (
    
    <Modal
      show={true}
      onHide={onHide}
      onClick={(e: MouseEvent) => e.stopPropagation()}
      dialogClassName="mw-100 w-75"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>{text`edit_texts`}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Form>
          {Object.entries(strings)
            .filter(([languageString, text]: [string, string]) => languageString.substring(0, 1) !== '_' && typeof text !== 'function')
            .map(([languageString, text]: [string, string]) => (
              <EditLocalizationFormControl
                {...{ key: languageString, languageString, text }}
              />
            ))
          }
        </Form>
      </Modal.Body>
    </Modal>
  );
}

export default EditLocalizationModal;