import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Nav, NavDropdown } from 'react-bootstrap';
import { use } from '../../redux/factory';
import { SmallSpinner } from '../shared/ReactToolbox';
import { useAuth } from '../shared/Login';
import { Image } from '../shared/Images';
import { useLocalization } from '../shared/ReactToolbox';

const FactoriesDropdown = () => {
  const { text } = useLocalization();
  useEffect(() => {
    getFactoriesList();
  }, []);
  const {
    factoriesList,
    getFactoriesList,
    changeUserFactory,
    getUserFactory,
  } = use.factories();
  const { user } = useAuth();

  if (getFactoriesList.isLoading || !factoriesList) return <SmallSpinner />;
  const factory = factoriesList[user.factory];
  if (getUserFactory.isLoading) return null;

  return (
    <Nav className="mr-auto">
      <NavDropdown
        title={
          factory
            ? (factory.logo
                ? <Image
                    src={factory.logo}
                    alt={factory.name}
                    className="factory-logo"
                  />
                : factory.name
              )
            : text`select_a_factory`
        }
        id="basic-nav-dropdown"
      >
        {Object.values(factoriesList).map(factory =>
          <NavDropdown.Item
            key={factory.id}
            onClick={() => changeUserFactory(factory)}
          >
            {factory.id === user.factory ? <strong>{factory.name}</strong> : factory.name}

          </NavDropdown.Item>
        )}
      </NavDropdown>
      {changeUserFactory.isLoading && <SmallSpinner />}
    </Nav>
  );
}

export default FactoriesDropdown;
