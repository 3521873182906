import React, { useEffect, forwardRef } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { ListGroup } from 'react-bootstrap';
import { useSetState, usePrevious, DragAndDropList } from '../shared/ReactToolbox';
import { use } from '../../redux/factory';
import { LoadingIndicator, SmallSpinner } from '../shared/ReactToolbox';
import { ObjectDescription } from './ObjectsList';

const ObjectItem = forwardRef(({ object }, ref) => {
  return (
    <ListGroup.Item
      className='objects-list'
      ref={ref}
    >
      <ObjectDescription object={object} link={true} />
    </ListGroup.Item>
  )
});

const AllObjectsList = () => {
  const { objectsList, getAllObjects, moveObject } = use.objects();
  const { configurationModelsList } = use.configurationModels();
  const [state, setState] = useSetState({
    objectsSelected: {},
    objectsInCopyModal: null,
    createModalActive: false,
    editObject: null,
    objectsArray: [],
    droppedObject: null,
  });

  const resetAllObjects = () => {
    // Keep a separate list of all objects in the state that can be dragged
    const objectsArray = objectsList && Object.values(objectsList)
      .reduce(
        (objectsArray, objects) => [ ...objectsArray, ...objects ? Object.values(objects) : []],
        []
      )
      .sort((a, b) => a.order > b.order ? 1 : -1);
    setState({ objectsArray })
  }

  useEffect(() => {
    resetAllObjects();
  }, []);
  const prevObjectsList = usePrevious(objectsList);

  useEffect(() => {

    if (!_.isEqual(objectsList, prevObjectsList)) {
     resetAllObjects();
    }
  }, [objectsList, prevObjectsList]);

  if (getAllObjects.isLoading || !objectsList || !configurationModelsList) return <LoadingIndicator />;

  const objectsArray = Object.values(objectsList)
    .reduce(
      (objectsArray, objects) => [ ...objectsArray, ...objects ? Object.values(objects) : []],
      []
    )
    .sort((a, b) => a.order > b.order ? 1 : -1);

  return (
    <>
      <ListGroup>
        <DragAndDropList
          propsArray={objectsArray.map(object => ({ object }))}
          onDrop={(index, targetIndex, reset ) => {
            const object = objectsArray[index];
            const target = objectsArray[targetIndex];
            if (object.id === target.id || moveObject.isLoading) {
              return;
            }

            setState({ droppedObject: object})
            moveObject(
              object,
              {
                args: {
                  target,
                  position: object.order > target.order ? 'right' : 'left',
                },
                callback: () => resetAllObjects(),
                onError: () => resetAllObjects()
              }
            );
          }}
          component={ObjectItem}
        />
      </ListGroup>
    </>
  );
};

export default AllObjectsList;

AllObjectsList.propTypes = {
  phase: PropTypes.object,
};
AllObjectsList.defaultProps = {
  phase: null,
};
