import React, { Component, useRef } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Table, Modal, Button } from 'react-bootstrap';
import moment from 'moment';
import { arrayToObject } from '../../utils/utils';
import { use } from '../../redux/factory';
import { useAuth } from '../shared/Login';
import { ObjectDescription } from '../objects/ObjectsList';
import {
  LoadingIndicator,
  DownloadButton,
  ListButton,
  useLocalization,
  useSetState,
} from '../shared/ReactToolbox';
import { getBaseUrl } from '../../utils/utils';
import axios from '../../utils/axios';

const ExportsList = () =>  {
  const { userFactory } = use.factories();
  const { text, strings } = useLocalization();
  const [state, setState] = useSetState({ showModalWithExport: null })
  const { getExports, exports, selectedProject } = use.projects();
  const { showModalWithExport } = state;
  const objectsList = useSelector((state) => state.objects)
  if (getExports.isLoading || !userFactory) return <LoadingIndicator />;
  if (!exports || exports.length === 0) {
    return <>{text`no_exports_found`}</>;
  }
  const { flow_steps } = userFactory;

  const statusById = arrayToObject(flow_steps, 'flow_step_id')

  const objectsById = {};
  Object.values(objectsList.list || {})
    .filter(o => o && !!o.list)
    .map(({ list }) => list && Object.values(list).map(o => { objectsById[o.id] = o }));

  let items = exports
    .sort((a, b) => a.selection_id > b.selection_id ? -1 : 1)
    .map(e => {
      const status = statusById[e.flow_step_id];
      const fsLocalizationKey = e.flow_step_localization_key;
      const psLocalizationKey = e.progress_state_localization_key;
      return (
        <tr
          key={e.selection_id}
          onClick={() => setState({ showModalWithExport: e })}
          className='exports-list'
        >
          <td>{e.selection_id}</td>
          <td>{e.objects.length}</td>
          <td>{moment(e.created_at).format('DD-MM-YYYY HH:mm')}</td>
          <td>{fsLocalizationKey && (strings.getString(fsLocalizationKey) || fsLocalizationKey)} </td>
          <td>{psLocalizationKey && (strings.getString(psLocalizationKey) || psLocalizationKey)} </td>
          <td>
            <ListButton
              onClick={() => setState({ showModalWithExport: e })}
              title={text`show_exported_objects`}
            />
            {e.is_final &&
              <DownloadButton
                title={text`download_exports`}
                onClick={() => {
                  // https://gist.github.com/javilobo8/097c30a233786be52070986d8cdb1743
                  axios({
                    url: `${getBaseUrl()}/api/projects/${selectedProject.id}/download/?selection_id=${e.selection_id}`,
                    method: 'GET',
                    responseType: 'blob',
                  }).then((response) => {
                    const project = selectedProject;
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', `${project.name}${project.project_number && ` (${project.project_number})`} - ${e.selection_id}.zip`);
                    document.body.appendChild(link);
                    link.click();
                  });
                }}
              />
            }
          </td>
        </tr>
      )
    }
  );

  return (<>
    <Table striped bordered hover>
      <thead>
        <tr>
          <th>{text`selection`}</th>
          <th>{text`objects`}</th>
          <th>{text`created_at`}</th>
          <th>{text`flow_step`}</th>
          <th>{text`export_status`}</th>
          <th>{text`actions`}</th>
        </tr>
      </thead>
      <tbody>
        {items}
      </tbody>

    </Table>
    <Modal
      show={!!showModalWithExport}
      onHide={() => setState({ showModalWithExport: null })}
      onClick={e => e.stopPropagation()}
      dialogClassName="mw-100 w-75"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>{text`export`}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>{text`object`}</th>
              <th>{text`finished_at`}</th>
              <th>{text`quotation`}</th>
            </tr>
          </thead>
          <tbody>
            {showModalWithExport && showModalWithExport.objects
              .map((o, index) => {
                return (
                  <tr key={index}>
                    <td><ObjectDescription object={objectsById[o.object_id]} /></td>
                    <td>{o.finished_at && moment(o.finished_at).format('DD-MM-YYYY HH:mm')}</td>
                    <td>
                      {/*href={quotationElfsquadUrl(objectsById[o.object_id])}*/}
                      {o.quotation_uuid && o.quotation_uuid.substring(0, 8)}
                    </td>
                  </tr>
                )
              })
            }
          </tbody>
        </Table>
      </Modal.Body>

      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={() => setState({ showModalWithExport: null })}
        >
          {text`close`}
        </Button>
      </Modal.Footer>
    </Modal>
  </>);
};

export default ExportsList;
