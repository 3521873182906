import React, { useEffect } from 'react';
import _ from 'lodash';
import { Tabs, Tab } from 'react-bootstrap';

import { useAuth } from '../shared/Login';
import { use } from '../../redux/factory';
import {
  LoadingIndicator,
  SmallSpinner,
  useSetState,
  CreateFileButton,
  ErrorPage,
  usePrevious,
  useLocalization,
} from '../shared/ReactToolbox';
import PhasesList from '../phases/PhasesList';
import ObjectsList from '../objects/ObjectsList';
import AllObjectsList from '../objects/AllObjectsList';
import ExportsList from '../exports/ExportsList';
import CreateObjectModal from '../objects/CreateObjectModal';

type StateType = {
  showCreateObjectModal: boolean;
  projectTab: 'phases' | 'order' | 'exports';
}

const PhasesPage = () => {
  const [state, setState] = useSetState<StateType>({
    showCreateObjectModal: false,
    projectTab: 'phases',
  } as const)
  const { selectedProject, getExports } = use.projects()
  const { getAllPhases } = use.phases();
  const { getAllObjects, objectsList } = use.objects();
  const { text, setLanguage } = useLocalization();
  const { user } = useAuth();

  useEffect(() => {
    if (user?.language) {
      setLanguage(user.language);
    } 
  }, [user?.language]);
  const prevSelectedProject = usePrevious(selectedProject);

  useEffect(() => {
    if (!_.isEqual(prevSelectedProject, selectedProject)) {
      setState({ projectTab: 'phases' });
    }
  }, [selectedProject, prevSelectedProject]);

  if (user && !user.factory && !user.is_staff) {
    return (
      <ErrorPage>
        <p>{text`factory_not_coupled_to_account`}</p>
      </ErrorPage>
    );
  }
  if (!user) return <SmallSpinner />
  return (
    <div className="phases-list">
      {getAllPhases.isLoading || getAllObjects.isLoading || getExports.isLoading
        ? <LoadingIndicator />
        :
          <>
            {selectedProject && !getAllObjects.isLoading &&

              <Tabs
                activeKey={state.projectTab}
                onSelect={projectTab => {
                  if (projectTab === 'phases') {
                    getAllPhases({ params: { project: selectedProject.id }});
                  } else if (projectTab === 'order') {
                    getAllPhases({ params: { project: selectedProject.id }});
                    getAllObjects({ params: { project: selectedProject.id }});
                  } else if (projectTab === 'exports') {
                    getExports(selectedProject);
                  } else {
                    return;
                  }
                  setState({ projectTab });
                }}
                className="mb-3"
              >
                <Tab eventKey="phases" title={text`phases`}>
                  {objectsList && Object.values(objectsList).length > 0
                    ? <ObjectsList phase={null} />
                    : <>
                      <span className="float-end">
                        <CreateFileButton
                          title={text`configure_new_object`}
                          onClick={() => setState({ showCreateObjectModal: true })}
                        />
                      </span>
                      <br />
                    </>
                  }
                  <PhasesList parent={null} />
                </Tab>
                <Tab eventKey="order" title={text`next`}>
                  {/* @ts-ignore Refactor AllObjectsList to functional component will remove typescript error */}
                  <AllObjectsList />
                </Tab>
                <Tab eventKey="exports" title={text`exports`}>
                  <ExportsList />
                </Tab>
              </Tabs>
            }
          </>
      }
      <CreateObjectModal
        phase={null}
        show={state.showCreateObjectModal}
        onHide={() => setState({ showCreateObjectModal: false })}
      />
    </div>
  )
}

export default PhasesPage;