export type AsObject<T> = {
  [id: number]: T;
}

type Instance = {
  id: number;
}
interface OrderedInstance extends Instance {
  order: number;
}

export interface ObjectType extends OrderedInstance {
  uuid: string;
  configuration_model_uuid: string;
  quotation_uuid: string;
  flow_step_id: number;
  name: string;
  project: number;
  phase: number | null;
};

export type ObjectsList = AsObject<ObjectType>;

export type AllObjectsList = {
  [phaseId: number]: ObjectsList;
}

export interface Project extends OrderedInstance {
  name: string;
  project_number: string | null;
  sales_order_prefix: string | null;
  sales_order: string | null;
  factory: number;
  client: number;
  xml: string;
}
export type ProjectsList = AsObject<Project>;

export interface Client extends OrderedInstance {
  name: string;
  client_number: string;
  factory: number;
}
export type ClientsList = AsObject<Client>;

export interface Language extends OrderedInstance {
  iso: string;
  name: string;
}
export type LanguagesList = AsObject<Language>;

export enum LocalizationKeyType {
  TEXT = 'text',
  TEXTAREA = 'textarea',
  STATUS = 'status',
}
export interface LocalizationKey extends OrderedInstance {
  value: string;
  type: LocalizationKeyType;
}
export type LocalizationKeysList = AsObject<LocalizationKey>;

export interface LocalizationString extends Instance {
  language: number;
  key: number;
  text: string;
}
export type LocalizationStringsList = AsObject<LocalizationString>;

export interface Factory extends Instance {
  id: number;
  customer_id: number;
  can_decrease_status: boolean;
  can_edit_clients_and_projects: boolean;
  force_unique_object_name: boolean;
  languages: number[];
  logo: string | null;
  name: string;
}

export type FactoriesList = AsObject<Factory>;

export type FlowStep = {
  id: number;
  name: string;
}
export interface UserFactory extends Instance {
    id: Factory['id'];
    name: Factory['name'];
    logo: Factory['logo'];
    can_decrease_status: Factory['can_decrease_status'];
    can_edit_clients_and_projects: Factory['can_edit_clients_and_projects'];
    languages: { [iso: string]: string };
    flow_steps: FlowStep[];
    cb_customer_code: string;
}

export type Email = {
  id: number;
  language: string;
  key: string;
  subject: string;
  message: string;
}
export type EmailsList = AsObject<Email>;
