import axios from 'axios';
import { getBaseUrl } from './utils';

// Get the unique pipeline id that is set during deployment
const apiVersion = process.env['REACT_APP_API_VERSION'];

// axios instance for our Backend
export default axios.create({
  baseURL: getBaseUrl(),
  ...apiVersion
    ? { headers: { 'X-API-Version': apiVersion } }
    : {},
});
