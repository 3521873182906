import React from 'react';
import PropTypes from 'prop-types';
import { NavDropdown } from 'react-bootstrap';
import { use } from '../../redux/factory';

export const formatClient = client => `${client.name}${client.client_number ? ` (${client.client_number})` : ''}`;

const Client = ({ client, selected }) => {
  const { clearAllPhases } = use.phases();
  const { clearAllObjects } = use.objects();
  const { selectClient } = use.clients();
  return (
    <NavDropdown.Item
      onClick={e => {
        clearAllPhases();
        clearAllObjects();
        selectClient(client);
      }}
    >
      {selected ? <strong>{formatClient(client)}</strong> : formatClient(client)}
    </NavDropdown.Item >
  );
};

Client.propTypes = {
  client: PropTypes.object.isRequired,
  selected: PropTypes.bool.isRequired,
};
export default Client;
