import { getBaseUrl } from '../../utils/utils';

export const Image = ({ src, isFrontend = false, alt, ...restProps }) => (
  <img
    src={src.search('://') === -1 && !isFrontend
      ? `${getBaseUrl()}${src}`
      : src
    }
    alt={alt}
    {...restProps}
  />
);
