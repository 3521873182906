import { use } from '../../redux/factory';
import { SmallSpinner, UploadTextButton, useLocalization } from '../shared/ReactToolbox';

const UploadXmlButton = () => {
  const { text } = useLocalization()
  const { createProjectFromXml, selectProject, clearProjectsList } = use.projects();
  const { setClient, selectClient, selectedClientId } = use.clients();
  return (
    <>
      <UploadTextButton
        title={text`upload_xml_and_create_project_and_client`}
        accept=".xml"
        onLoadFile={xml => {
          createProjectFromXml(
            xml,
            { callback: ({ project: newProject, client }: any) => {
              if (client.id === selectedClientId) {
                selectProject(newProject)
              } else {
                clearProjectsList();
                setClient(client);
                selectClient(client);
              }
            }},
          );
        }}
      />
      {createProjectFromXml.isLoading && <SmallSpinner />}
    </>
  );
}

export default UploadXmlButton;
