import React, { useEffect, useMemo, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

import { use } from './redux/factory';
import { Login } from './components/shared/Login';
import Dashboard from './components/dashboard/Dashboard';
import PhasesPage from './components/phases/PhasesPage';
import ManageLocalizationPage from './components/localization/ManageLocalizationPage';
import ManageEmailsPage from './components/localization/ManageEmailsPage';
import ManageFactoriesPage from './components/factories/ManageFactoriesPage';
import AuthenticatedRoute from './utils/AuthenticatedRoute';
import {LocalizationProvider,
  SmallSpinner,
  useLocalization,
  SyncButton,
  useError,
  ErrorPage,
} from './components/shared/ReactToolbox';
import LanguageDropdown from './components/localization/LanguageDropdown';

const LoginLabel = () => {
  const { text } = useLocalization();
  return (
    <div className='d-flex'>
      <h1>
        {text`login`}
      </h1>
      <LanguageDropdown />
    </div>
  )
};

const ReloadRequired = () => {
  const { text } = useLocalization();

  return (
    <ErrorPage>
      {text`reload_required`}
      <SyncButton onClick={() => window.location.reload()} />
    </ErrorPage>
  );
}

const App = () => {
  const [reloadRequired, setReloadRequired] = useState(false);

  const { getLocalizationStrings, localizationStrings } = use.languages();
  const { error } = useError()

  useEffect(() => {
    if (!localizationStrings) {
      getLocalizationStrings();
    }
  }, [localizationStrings]);
  useEffect(() => {
    if (error?.response?.data?.detail === 'Incorrect api version') {
      setReloadRequired(true);
    }
  }, [error]);

  // Memoize app to prevent to prevent triggering a complete rerender and reload when the language
  // is changed.
  const app = useMemo(() => (
    (!localizationStrings)
    ? <SmallSpinner />
    : (
      <DndProvider backend={HTML5Backend}>
        <LocalizationProvider
          lang='nl'
          languages={['nl', 'fr']}
          localization={localizationStrings}
        >
          {reloadRequired
            ? <ReloadRequired />
            : <>
                <ToastContainer hideProgressBar={true} newestOnTop={true} />
                <Routes>
                  <Route path="/login" element={
                    <Login label={<LoginLabel />} />
                  } />
                  <Route
                    path="/"
                    element={
                      <AuthenticatedRoute
                        exact path='/'
                        component={
                          (props: any) => <AuthenticatedRoute component={Dashboard} />
                        }
                      />
                    }
                  >
                    <Route
                      index
                      element={<PhasesPage />}
                    />
                    <Route path="/localization" element={<ManageLocalizationPage />} />
                    <Route path="/factories" element={<ManageFactoriesPage />} />
                    <Route path="/emails" element={<ManageEmailsPage />} />
                  </Route>
                </Routes>
              </>
          }
        </LocalizationProvider>
      </DndProvider>
    )
  ), [!!localizationStrings, reloadRequired]);
  return app;
};

export default App;
