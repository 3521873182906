import React from 'react';
import thunk from 'redux-thunk';
import { Provider } from 'react-redux';
import { createBrowserHistory } from 'history';
import { applyMiddleware, createStore } from 'redux';
import { retrieveState } from './components/shared/Login';
import rootReducer from './redux/reducer';
import { ErrorBoundary } from './components/shared/ReactToolbox';

const Root = ({ children }) => {
  const history = createBrowserHistory();
  const middleware = [thunk];

  const store = createStore(
    rootReducer(history),
    retrieveState(),
    applyMiddleware(...middleware)
  );

  return (
    <Provider store={store}>
      <ErrorBoundary>
        {children}
      </ErrorBoundary>
    </Provider>
  );
};

export default Root;
