import { Navigate } from 'react-router-dom';
import axios from '../../utils/axios';
import { toastOnError } from '../../utils/utils';
import { loginFactory } from './ReactToolbox';

export const {
  // Login component
  Login,
  useAuth,
  authReducer,
  // Hook to get all redux actions that can be directly dispatched by calling them
  useLogin,
  // All redux actions that require dispatch
  login,
  getCurrentUser,
  setCurrentUser,
  setToken,
  unsetCurrentUser,
  logout,
  storeState,
  retrieveState,
} = loginFactory({
  passwordResetUrl: `${axios.defaults.baseURL}/accounts/password/reset/`,
  // @ts-ignore To do: fix the typescript error
  axios,
  onError: toastOnError,
  loginUrl: '/api/token/login/',
  getUserUrl: '/api/users/me/',
  logoutUrl: '/api/token/logout/',
  localStoragePrefix: 'cadblanche',
  authenticatedComponent: () => <Navigate to='/' />,
});
