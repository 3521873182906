import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { use } from '../../redux/factory';
import Phase from './Phase';
import {
  LoadingIndicator,
  CreateEditModal,
  CreateFolderButton,
  CreateSubFolderButton,
  useSetState,
  useLocalization,
} from '../shared/ReactToolbox';

const PhasesList = ({ parent }) => {
  const { text } = useLocalization();
  const [state, setState] = useSetState({
    showNewModal: false,
  })
  const {
    clearPhasesList: clearPhasesListBase,
    createPhase,
    phasesList,
    movePhase,
    selectedPhaseId,
    getAllPhases,
  } = use.phases({ parent });
  const { selectedProjectId } = use.projects();

  useEffect(() => { clearPhasesList(); }, []);

  const clearPhasesList = () => {
    if (selectedProjectId !== null && !phasesList && !getAllPhases.isLoading) {
      clearPhasesListBase({
        project: selectedProjectId,
        parent: parent ? parent.id : 'null',
      });
    }
  }

  if (getAllPhases.isLoading ) return <LoadingIndicator />;
  if (!selectedProjectId) return null;

  const phasesListArray = phasesList ? Object.values(phasesList) : [];
  let items = phasesListArray
    .sort((a, b) => a.sib_order > b.sib_order ? 1 : -1)
    .map((phase, index) => {
    return <div key={phase.id} className="phase-item">
      <Phase
        key={phase.id}
        phase={phase}
        onMoveUp={index > 0
          ? () => movePhase(phase, { args: { target: phasesListArray[index - 1], position: 'left' } })
          : null
        }
        onMoveDown={index < (phasesListArray.length - 1)
          ? () => movePhase(phase, { args: { target: phasesListArray[index + 1], position: 'right' } })
          : null
        }
        selected={phase.id === selectedPhaseId}
        parent={parent}
      />
    </div>;
  });

  return <>
    <div className="phase-folder">
      {phasesListArray.length > 0
        ? <>
          {items}
          <br />
          <div className="phase-item">
            <CreateFolderButton
              title={text`create_phase`}
              onClick={() => setState({ showNewModal: true })}
            />
          </div>
        </>
        :
          <div className="phase-item">
            <CreateSubFolderButton
              title={text`create_phase`}
              onClick={() => setState({ showNewModal: true })}
            />
          </div>
      }
    </div>

    <CreateEditModal
      show={state.showNewModal}
      modalTitle={text`new_phase`}
      loading={createPhase.isLoading}
      onHide={() => setState({ showNewModal: false })}
      initialState={{
        name: '',
        project: selectedProjectId, parent: parent ? parent.id : null,
      }}
      formFields={{ name: { label: text`name` } }}
      onSave={phase => createPhase(
        phase,
        { callback: () => setState({ showNewModal: false }) }
      )}
    />
  </>;
};
PhasesList.propTypes = {
  parent: PropTypes.object,
};
PhasesList.defaultProps = {
  parent: null,
};

export default PhasesList;
