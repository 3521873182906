import React, { useEffect, useMemo } from 'react';
import Fuse from 'fuse.js';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { use } from '../../redux/factory';
import ProjectItem, { useFormFields } from './ProjectItem';
import {
  LoadingIndicator,
  useSetState,
  usePrevious,
  CreateButton,
  CreateEditModal as CreateModal,
  SearchBox,
  useLocalization,
} from '../shared/ReactToolbox';
import { formatXml } from './ProjectItem';
import UploadXmlButton from './UploadXmlButton';
import { Project } from '../../redux/types';
const ProjectsList = () => {
  const { text } = useLocalization();
  const formFields = useFormFields();
  const [state, setState] = useSetState({
    createModalActive: false,
    search: '',
  });

  const { getProjectsList, projectsList, selectedProjectId, createProject, selectProject } = use.projects();
  const { selectedClientId, selectedClient } = use.clients();
  const prevSelectedClientId = usePrevious(selectedClientId);
  useEffect(() => {
    getProjectsList({ params: { client: selectedClientId } });
  }, []);
  useEffect(() => {
    if (selectedClientId !== prevSelectedClientId) {
      getProjectsList({ params: { client: selectedClientId } });
    }
  }, [selectedClientId, prevSelectedClientId]);

  const { userFactory: factory } = use.factories()

  const fuse = useMemo(() => new Fuse(
    projectsList ? Object.values(projectsList) : [] as Project[],
    {
      findAllMatches: true,
      threshold: 0.2,
      shouldSort: false,
      includeScore: true,
      keys: ['name', 'project_number', 'sales_order', 'sales_order_prefix'],
    }
  ), [projectsList]);

  if (getProjectsList.isLoading || !factory || !projectsList) return <LoadingIndicator />;
  if (!selectedClientId) return null;

  let items = (
    projectsList && (state.search
      ? fuse.search(state.search).map(({ item }) => item)  as Project[]
      : Object.values(projectsList) as Project[]
    ).map((project: Project) => (
    // Use explicit html instead of Nav.Link component because Nav.Link uses portals which makes it impossible for
    // modals to use spaces
    <div
      className='project-item'
      key={project.id}
      onClick={e => {
        e.stopPropagation();
        e.preventDefault();
      }}
    >
      <ProjectItem project={project} selected={project.id === selectedProjectId} />
    </div>
  )));

  return (
    <>
      <SearchBox
        className="project-search"
        value={state.search}
        onChange={search => setState({ search })}
        onClear={() => setState({ search: '' })}
      />

      {items}

      <div
        className='projects-actions'
        role='button'
      >
        {factory.can_edit_clients_and_projects &&
          <CreateButton
            title={text`create_project`}
            onClick={() => setState({ createModalActive: true })}
          />
        }
        <UploadXmlButton />
      </div>

      <CreateModal
        show={state.createModalActive}
        modalTitle={text`new_project`}
        onHide={() => setState({ createModalActive: false })}
        initialState={{ client: selectedClientId } as Project}
        formFields={formFields}
        loading={createProject.isLoading}
        validate={({ name }: any = {}) =>
          Object.values(projectsList).find((project: any) => name === project.name)
            ? { name: text`project_name_not_unique_error` }
            : false
        }
        onSave={(newProject: any) => createProject(
          {
            ...newProject,
            xml: formatXml({ project: newProject, client: selectedClient }),
          },
          {
            callback: (newProject: any) => {
              selectProject(newProject);
              setState({ createModalActive: false })
            },
          }
        )}
      />
    </>
  );
}

export default ProjectsList;
