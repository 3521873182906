import React, { useEffect } from 'react';
import { Route, useParams, Navigate } from 'react-router-dom';

import { useAuth } from '../components/shared/Login';

// https://dev.to/iamandrewluca/private-route-in-react-router-v6-lg5
const AuthenticatedRoute = ({ component: Component, ...restProps }) => {
  const params = useParams();
  const { isAuthenticated } = useAuth();

  return isAuthenticated ? <Component {...params} {...restProps} /> : <Navigate to='/login' />;
}
export default AuthenticatedRoute;
