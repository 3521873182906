import React, { Component } from 'react';
import { useSelector } from 'react-redux';
import { Form } from 'react-bootstrap';
import { useLocalization } from '../shared/ReactToolbox';

const SelectPhase = ({ phase = null, disabled, ...restProps }) => {
  const { text } = useLocalization();
  const phases = useSelector(state => state.phases);
  const {
    currentPhase,
    selectedPhase,
    onSelectPhase,
    disableCurrent,
    disableDescendants,
    disableParent,
  } = restProps;
  const phaseId = phase ? phase.id : null;
  const phasesList = phases.list[phaseId].list;
  const currentPhaseId = currentPhase ? currentPhase.id : null;

  const phaseName = phase === null ? <>{text`without_phase`}<br /></> : phase.name;
  return <>
    <Form.Check
      type="radio"
      className="float-start"
      checked={selectedPhase !== false && phaseId === (selectedPhase ? selectedPhase.id : null)}
      disabled={
        disabled
        || (disableCurrent && phaseId === currentPhaseId)
        || (disableParent && !!phasesList[currentPhaseId])
      }
      onChange={e => onSelectPhase(phase)}
    />
    <label
      className="phase-select"
      onClick={() => onSelectPhase(phase)}
    >
      {phaseId === currentPhaseId ? <strong>{phaseName}</strong> : phaseName}
    </label>
    <ul>
      {phasesList && Object.values(phasesList).map(phase =>
        <li key={phase.id}>
          <SelectPhase
            phase={phase}
            disabled={disabled || (disableDescendants && phaseId === currentPhaseId)}
            {...restProps}
           />
        </li>
      )}
    </ul>
  </>
};

export default SelectPhase;
