import { Container, Badge } from 'react-bootstrap';
import { use } from '../../redux/factory';
import {
  SmallSpinner,
  DataTable,
  useLocalization,
  DataTableColumn,
  CreateButton,
  DeleteConfirmButton,
  useSetState,
  CreateEditModal as CreateModal,
  CreateEditModal as EditModal,
  FormFields,
  FormCheckbox,
  FormBadgesSelection,
  FormBadgesSelectionProps,
  CheckIndicator,
} from '../shared/ReactToolbox';
import {
  Factory,
  FactoriesList,
  Language,
  LanguagesList,
} from '../../redux/types';
import { sortedObjectValues, sortedByOrder } from '../../utils/utils';

type FactoryListState = {
  createModalActive: boolean;
  factoryInEditModal: null | Partial<Factory>;
}
const InitialState: FactoryListState = {
  createModalActive: false,
  factoryInEditModal: null,
}
const LanguagesFormField = (props: FormBadgesSelectionProps) => {
  const { languagesList } = use.languages();
  return (
    <FormBadgesSelection
      {...props}
      multiple
      integer
      list={sortedObjectValues(languagesList as LanguagesList)}
    />
  );
}

const ManageFactoriesPage = () => {
  const [state, setState] = useSetState(InitialState);
  const { factoriesList, createFactory, updateFactory, deleteFactory } = use.factories();
  const { getLocalizationStringsList } = use.localizationStrings();
  const { text } = useLocalization();
  const { languagesList } = use.languages();
  const factoryFormFields = {
    name: {
      label: text`name`,
      required: true,
    },
    customer_id: {
      label: <i>customer_id</i>,
      type: 'number',
      required: true,
    },
    can_edit_clients_and_projects: {
      label: text`factory_can_edit_clients_and_projects`,
      component: FormCheckbox,
    },
    can_decrease_status: {
      label: text`factory_can_decrease_status`,
      component: FormCheckbox,
    },
    languages: {
      label: text`factory_languages`,
      component: LanguagesFormField,
      required: true,
    },
  } as FormFields;
  const columns = [
    {
      name: text`name`,
      selector: 'name',
    },
    {
      name: 'customer_id',
      selector: 'customer_id',
    },
    {
      name: text`factory_can_edit_clients_and_projects`,
      selector: ({ can_edit_clients_and_projects }: Factory) =>
        <CheckIndicator checked={can_edit_clients_and_projects} />
    },
    {
      name: text`factory_can_decrease_status`,
      selector: ({ can_decrease_status }: Factory) =>
        <CheckIndicator checked={can_decrease_status} />
    },
    {
      name: text`factory_languages`,
      selector: ({ languages }: Factory) => 
        sortedByOrder(languages
          .map((id: number) => languagesList[id] as Language)
          .filter((language: Language | undefined) => !!language)
        )
          .map(
            ({ name }: Language, index: number) => (
              <Badge bg='primary' key={index}>
                {name}
              </Badge>
            )
          ),
    },
    {
      name: text`actions`,
      selector: (factory: Factory) => (
        <>
          <DeleteConfirmButton
            className='float-end'
            onDelete={() => deleteFactory(factory, { callback })}
            title={text`edit_factory`}
            loading={deleteFactory.isLoading && deleteFactory?.data?.id === factory.id}
          />
        </>
      )
    },
  ] as DataTableColumn<Factory>[];
  
  if (!factoriesList || !languagesList) return <SmallSpinner />

  const callback = () => {
    getLocalizationStringsList({ callback: () => {
      setState(InitialState)
    } });
  }

  return (
    <Container className="container-list">
      <DataTable
        showHeader={false}
        rowsPerPage={null}
        columns={columns}
        data={Object.values(factoriesList as FactoriesList)}
        orderByDefault='order'
        onClickRow={({ logo, ...factory }: Factory) => setState({ factoryInEditModal: factory})}
        className='table-click'
      />
      
      <CreateButton
        className='float-end'
        onClick={() => setState({ createModalActive: true })}
        title={text`new_factory`}
      />
      
      <CreateModal
        show={state.createModalActive}
        modalTitle={<>{text`new_factory`}</>}
        onHide={() => setState({ createModalActive: false })}
        initialState={{
          name: '',
          can_decrease_status: false,
          can_edit_clients_and_projects: false,
          languages: [],
        }}
        formFields={factoryFormFields}
        loading={createFactory.isLoading || getLocalizationStringsList.isLoading}
        onSave={(factory: any) => {
          createFactory(
            factory,
            {
              callback,
            }
          )
        }}
      />
      {state.factoryInEditModal && (
        <EditModal
          show={!!state.factoryInEditModal}
          modalTitle={<>{text`edit_factory`}</>}
          onHide={() => setState({ factoryInEditModal: null })}
          initialState={state.factoryInEditModal}
          formFields={factoryFormFields}
          loading={updateFactory.isLoading || getLocalizationStringsList.isLoading}
          onSave={(factory: any) => {
            updateFactory(
              factory,
              {
                callback,
              }
            )
          }}
        />
      )}
    </Container>
  )
}
export default ManageFactoriesPage;