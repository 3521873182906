import axios from './axios';
import { toast } from 'react-toastify';

export const stripHtml = (html: string | null | undefined) => (html ? html.replace(/(<([^>]+)>)/gi, '') : '');

export const setAxiosAuthToken = (token: string) => {
  if (typeof token !== 'undefined' && token) {
    // Apply for every request
    axios.defaults.headers.common['Authorization'] = 'Token ' + token;
  } else {
    // Delete auth header
    delete axios.defaults.headers.common['Authorization'];
  }
};

export const toastOnError = (error: any) => {
  if (error.response) {
    const { data } = error.response;
    if (data.detail) {
      // Django Api exception
      if (data.detail === 'Invalid token.') {
        // You are no longer logged in. Redirect to login page and do not show error
        localStorage.clear();
        window.location.href = '/login';
        return;
      }
      toast.error(data.detail);
    } else if (data.message) {
      // Custom error response
      toast.error(data.message);
      if (data.error) {
        console.error(data.error);
      }
    } else {
      toast.error(JSON.stringify(error.response.data));
    }
  } else if (error.message) {
    toast.error(JSON.stringify(error.message));
  } else {
    toast.error(JSON.stringify(error));
  }
  console.error(error)
};

export const isEmpty = (value: any) =>
  value === undefined ||
  value === null ||
  (typeof value === 'object' && Object.keys(value).length === 0) ||
  (typeof value === 'string' && value.trim().length === 0);

export const arrayToObject = (array: any[], byKey: string) => Object.fromEntries(array.map(obj => [obj[byKey], obj]));

export const getBaseUrl = () => {
    if (process.env.REACT_APP_BACKEND_URL) return process.env.REACT_APP_BACKEND_URL;
    // Fallback for .env not working yet
    if (window.location.hostname === 'frontend.cadblanche.eu' || window.location.hostname === 'projectmanager.cadblanche.eu') {
      return 'https://admin.projectmanager.cadblanche.eu';
    }
    return `https://backend.${window.location.hostname}`;
};


export const sortedByOrder = <T extends { order: number; [key: string]: any}>(list: T[], asc?: boolean) => {
  const num = asc || typeof asc === 'undefined'? 1 : -1
  return list.sort((obj1: T, obj2: T) => obj1.order > obj2.order ? num : -num);
}
export const sortedObjectValues = <T extends { order: number; [key: string]: any}>(objById: { [id: string]: T }, asc?: boolean) => {
  return sortedByOrder(Object.values(objById), asc);
}
