import { combineReducers } from 'redux';

// import { LOGIN_UNSET_CURRENT_USER } from './actions/Login';
// import { CLEAR_FACTORY_STATE } from './actions/Factories';
import factory from './factory';
import { authReducer, storeState } from '../components/shared/Login';
const consoleLogReducer = (state = null, { type, ...action }) => {
  if (window.location.hostname !== 'projectmanager.cadblanche.eu') {
    // To do: replace with .env variable
    console.log(type, action, state);
  }
  return state;
}

const reducer = history => {
  const rootReducer = (state, action) => consoleLogReducer(combineReducers({
    // router: connectRouter(history),
    ...factory.reducers,
    ...authReducer,
  })(state, action), action);

  return (state, action) => {
    const newState = rootReducer(state, action);
    return newState;
  };
}

export default reducer;
