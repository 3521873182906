import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Modal, Button } from 'react-bootstrap';
import { SmallSpinner, MoveButton, useSetState, useLocalization } from '../shared/ReactToolbox';
import SelectPhase from '../phases/SelectPhase';
import { use } from '../../redux/factory';

const MovePhaseModalButton = ({ phase, loading, show, onShow, onHide: onHideBase, className }) => {
  const { text } = useLocalization();
  const { movePhase } = use.phases();
  const phases = useSelector(state => state.phases);
  const initialState = {
    // Normally you would use null when no phase is selected. However the null phase is the root phase
    // which is a valid selection
    selectedPhase: false,
    show: false,
  }

  const [state, setState] = useSetState(initialState);

  const onMovePhase = (e) => {
    movePhase(
      phase,
      {
        args: {
          target:
            state.selectedPhase === null
            ? Object.values(phases.list[null].list)[0]
            : state.selectedPhase,
          position:
            state.selectedPhase === null
            ? 'last-sibling'
            : 'last-child',
        },
        callback: () => {
          onHide();
          toast.success(text`phase_moved_successfully`);
        },
      }
    );
  }

  const onHide = () => {
    setState(initialState, () => {
      if (typeof onHideBase === 'function') {
        onHideBase();
      }
    });
  }

  return <>
    <MoveButton
      title={text`move_phase`}
      className={className}
      onClick={() => {
        if (typeof onShow === 'function') {
          onShow();
        } else {
          setState({ show: true });
        }
      }}
    />
    <Modal
      show={show || state.show}
      onHide={onHide}
      onClick={e => e.stopPropagation()}
      dialogClassName="mw-100 w-50"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>{text`move_phase`}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <SelectPhase
          phase={null}
          currentPhase={phase}
          disableCurrent
          disableDescendants
          disableParent
          selectedPhase={state.selectedPhase}
          onSelectPhase={selectedPhase => setState({ selectedPhase })}
        />
      </Modal.Body>

      <Modal.Footer>
        {loading && <SmallSpinner />}
        <Button
          variant="secondary"
          onClick={onHide}
        >
          {text`close`}
        </Button>
        <Button
          variant="primary"
          onClick={onMovePhase}
          disabled={state.selectedPhase === false}
        >
          {text`move`}
        </Button>
      </Modal.Footer>
    </Modal>
  </>;
};
MovePhaseModalButton.propTypes = {
  phase: PropTypes.object.isRequired,
  onShow: PropTypes.func,
  onHide: PropTypes.func,
  show: PropTypes.bool,
}
MovePhaseModalButton.defaultProps = {
  onShow: null,
  onHide: null,
  show: false,
}

export default MovePhaseModalButton;
