import React from 'react';
import { createRoot } from 'react-dom/client';
import * as Sentry from '@sentry/react';
import { BrowserRouter } from 'react-router-dom';

import 'react-toastify/dist/ReactToastify.css';
import './index.scss'; // Bootstrap is imported here

import App from './App';
import reportWebVitals from './reportWebVitals';
import Root from './Root';

const dsn = process.env['REACT_APP_SENTRY_DSN'];
if (dsn) {
  Sentry.init({
    dsn,
    integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
    environment: process.env['REACT_APP_SENTRY_ENVIRONMENT'],
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

const root = createRoot(document.getElementById('root')!);
root.render(
  // <React.StrictMode>
  <BrowserRouter>
    <Root>
      <App />
    </Root>
  </BrowserRouter>
  // </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
